.heading {
  text-align: center;
  justify-content: center !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  letter-spacing: 0px;
  color: rgb(4, 17, 29);
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}
.tableContainer {
  box-shadow: none !important;
}
.container{
  height: 100vh;
}
.profileView {
  align-items: center;
  display: flex !important;
  font-size: 14px !important;
  color: rgb(4, 17, 29) !important;
}
.action{
  color: rgb(168, 10, 10) !important;
  font-size: 14px !important;  
  font-weight: 600 !important;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.paragraph {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
  color: #74828e;
}
.tabletitle {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: rgb(4, 17, 29) !important;
}
.tableRow {
  border-left: none !important;
  border-right: none !important;
}
.tableRow:hover {
  box-shadow: 0px 0px 8px 0px rgba(4, 16, 28, 0.25098039215686274);
}
.dataView {
  font-size: 14px !important;
  color: rgb(4, 17, 29) !important;
  font-weight: 600 !important;
  text-align: center;
  align-items: center;
}
.profileView .dataView {
  border-bottom: none !important;
}
// These classes are for future use while functioning of page
.active::dataview {
  font-size: 14px !important;
  color: rgb(52, 199, 123) !important;
  font-weight: 600 !important;
}
.disable::dataview {
  font-size: 14px !important;
  color: rgb(235, 87, 87) !important;
  font-weight: 600 !important;
}
.filterRow {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.selectButtons {
  width: 240px !important;
  margin: 10px;
  border-radius: 10px !important;
  border-color: #585858 !important;
}
.selectButtons:hover {
  background-color: #ffff;
  box-shadow: 0px 0px 8px 0px rgba(4, 16, 28, 0.25098039215686274);
  border: none !important;
  // border-color: #585858 !important;
}
.form {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: 50px;
}
.paginationRow {
  margin-top: 20px;
  justify-content: center;
}
.paginationButton {
  color: #585858 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  border-color: #ebebeb !important;
}
.paginationButton:hover {
  transition: all 0.2s ease 0s;
  background-color: #ffff;
  box-shadow: 0px 0px 8px 0px rgba(4, 16, 28, 0.25098039215686274);
}
