.Button {
  margin: 10px 30px 10px 10px !important;
}
.sendLink {
  padding: 7px 10px !important;
}
.otpInput {
  margin-top: 40px;
}
.forgetLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.verifyBtn {
  text-decoration: none;
  color: #f1f1f1;
}
.ChangePsdBtn {
  text-align: center;
}
.box {
  width: 30%;
  height: 300px !important;
}
.gridMenu {
  height: 300px;
}
.menuBtn {
  color: rgba(0, 0, 0, 0.87);
}
.tempImage {
  width: 110px !important;
  height: 110px !important;
  text-align: center !important;
  margin-left: 43%;
  margin-top: 15px;
  border-radius: 50%;
}
.tabbar {
  width: 320px;
}
.passwordHeader {
  font-size: 40px !important;
  font-weight: 600 !important;
}
.paperBlock {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.forgetpasswordLink {
  float: right !important ;
  margin-top: 13px;
}
